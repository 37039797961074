const candidateCardDataFormat = (candidate) => {
    return {
        candidate: { current_address: candidate?.candidate?.address, ...candidate?.candidate },
        ...(candidate?.candidate),
        ...candidate,
        item_id: candidate?.id,
        profile: {
            currency: candidate?.candidate?.currency,
            expected_salary: candidate?.candidate?.expected_salary || null,
            experience: candidate?.candidate?.experience,
            position: candidate?.candidate?.position,
            price: candidate?.candidate?.price,
            status: candidate?.candidate?.status
        }
    }
}

export default candidateCardDataFormat