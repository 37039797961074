import React, { useState, useContext, useEffect } from "react"

import { COMPANY_SIDEBAR_MENU_STEP } from "../../../constants/sidebar-menu"
import { PAID_CVS_MENU } from "../../../constants/profile-menu"

import { GlobalAuthContext } from "../../../context/authContext"

import { PAID_CV_FETCH } from "../../../components/company-profile/statistics/action/statisticsAction"

import CompanyProfileLayout from "../../../components/layout/company-profile"
import CompanyPaidCvs from "../../../components/company-profile/paid-cv/paid-cvs"
import Spinner from "../../../components/spinner/spinner"

const PaidCvsPage = ({ location }) => {
  const [paidCVs, setPaidCVs] = useState({})
  const [processing, setProcessing] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageDetails, setPageDetails] = useState({})

  const { state, dispatch } = useContext(GlobalAuthContext)

  useEffect(() => {
    if (state && state.user && state.user.role === 3 && state.hasProfile) {
      PAID_CV_FETCH(
        dispatch,
        currentPage,
        setPageDetails,
        setPaidCVs,
        setLoading
      )
    }
  }, [processing, currentPage, state.user, state.hasProfile]) // eslint-disable-line react-hooks/exhaustive-deps

  return isLoading ? (
    <Spinner />
  ) : (
    <CompanyProfileLayout
      menu={PAID_CVS_MENU}
      sidebarMenu={COMPANY_SIDEBAR_MENU_STEP}
      activeSidebar="Paid CVs"
      active="Paid CVs"
      title="Paid CVs"
      location={location}
    >
      <CompanyPaidCvs
        processing={processing}
        setProcessing={setProcessing}
        currentPage={currentPage}
        pageDetails={pageDetails}
        setCurrentPage={setCurrentPage}
        paidCvs={paidCVs}
      />
    </CompanyProfileLayout>
  )
}

export default PaidCvsPage
