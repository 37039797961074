import React from "react"

import candidateCardDataFormat from "../../../utils/candidate-card-data-format"

import "./paid-cv.scss"
import CandidateCard from "../../candidate-card/candidate-card"
import Paginator from "../../paginator/paginator"

const CompanyPaidCvs = ({
  processing,
  currentPage,
  paidCvs,
  pageDetails,
  setProcessing,
  setCurrentPage,
}) => {
  return (
    <div className="company-statistics mt-5 mb-5">
      <p className="top-text mb-4">
        The Paid CVs will be removed in every 15days, from the time of purchase.
        So Please Proceed with hiring process as soon as possible.
      </p>
      <div className="top-candidates-section mb-4">
        {paidCvs && paidCvs.length > 0 ? (
          paidCvs.map((candidate, index) => (
            <CandidateCard
              key={index}
              loading={processing}
              setLoading={setProcessing}
              candidate={candidateCardDataFormat(candidate)}
              shortlist={paidCvs}
              page="paid-cvs"
              // displayPaidCvsDeleteButton={true}
            />
          ))
        ) : (
          <p>You haven't purchased any CV yet.</p>
        )}
      </div>
      {pageDetails && pageDetails.total_pages && pageDetails.total_pages > 1 && (
        <div className="float-right mt-4">
          <Paginator
            pageCount={pageDetails.total_pages}
            pageRangeDisplayed={2}
            selected={currentPage}
            setCurrentPage={setCurrentPage}
            initialPage={currentPage}
            page="paid-cvs"
            marginPagesDisplayed={2}
          />
        </div>
      )}
    </div>
  )
}

export default CompanyPaidCvs
